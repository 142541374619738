.editor {
    position: relative;
    width: 100%;
    display: grid;
    padding: 0 30px;
    grid-template-columns: auto auto;
    justify-items: left;
    align-items: start;
}

hr {
    margin: 15px;
}
h1 {
    font-size: 1.5em;
    text-align: center;
    margin: 15px;
}
h2, h3 {
    font-size: 0.8em;
}

ul {
    padding: 0 30px;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}

li {
    font-size: 1em;
    padding: 0;
    list-style-type: none;
    margin: 5px;
}

.editor .title {
    font-weight: bold;
    padding: 5px;
}
.editor .author {
    color: #3BF;
    font-size: 0.8em;
    padding: 5px;
}

.editor .link-button,
.editor .link-button:hover,
.editor .link-button:focus
 {
    border-radius: 3px;
    padding: 1px 3px;
}

.editor .add-item {
    width: 100%;
    border-radius: 2px;    
    padding: 10px 18px;    
    margin-bottom: 34px;      
  }

.editor form {    
    max-width: 300px;    
}

.editor form input {
    width: 100%;
    margin-right: auto;
}

.editor .image-table li,
.editor .shelf-table li {
    width: 100%;
    display: flex;
    align-items: center;    
    border-bottom: 1px solid #555;    
}
.editor .image-table .controls,
.editor .shelf-table .controls {
    margin-left: auto;
}
.editor .image-table button,
.editor .shelf-table button {
    margin: 7px 5px;
}

.editor .thumbnail {
    height: 30px;
    width: 6    0px;
    padding: 5px;
}

.editor .outerbar {
    display: block;
    margin: 5px 0px;
    width: 90%;
    height: 20px;
}
.editor .innerbar {
    height: 100%;
    font-size: 0.7em;
    margin: 3px 5px;
}

/* Theme: light/dark mode */

.light .editor .outerbar {
    border: solid #777;
}
.light .editor .innerbar {
    color: #000;
    background-color: #3BF;
}

.dark .editor .outerbar {
    border: solid #777;
}
.dark .editor .innerbar {
    color: #FFF;
    background-color: #3BF;
}