/* Fonts */
body {
    font-family: Arial, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.menu-modal-content li,
.dark .search-modal-content {
    font-weight: normal;
}
.menu-modal-content a, 
.menu-modal-content button {
    font-family: Tahoma, 'Roboto Mono', Geneva, Verdana, sans-serif;
    font-size: 0.83em;
    font-weight: 600;
    text-decoration: none;
 }
 .menu-modal-content a:hover {
    text-decoration: underline;
 }
 .search-modal-content-button {
    font-size: 0.84em;
 }

.app-background {
    background-color: #333;
    position: fixed;
    height: 100vh;
    top: 0px;
    transition: opacity 200ms ease 0s;
    width: 100%;
    z-index: -3;
}

 /* Common item colours (do not change between themes) */
.Home {
    color: rgb(223, 50, 25) !important;
}
.Fireside {
    color: rgb(223, 120, 25) !important;
}
.Forest {
    color: rgb(0, 160, 101) !important;
}
.Caves {
    color: rgb(20, 120, 200) !important;
}


/* Light Theme */
.light {
    background-color: #fff;
    color: #333;
}
.light header {
    color: #fff;
}
.light hr {
    border: 0;
    border-top: 1px solid #999;
}
.light .menu-modal-content hr {
    border-top: 1px solid #000;
}

.light a {
    color: #09f;  
}
.light .modal {
    background-color: rgba(255, 255, 255, 0.4);
}
.light .menu-modal-content,
.light .search-modal-content {
    background-color: #DDD;    
}
.light .menu-modal-content a, 
.light .menu-modal-content button,
.light .search-modal-content-button {
    color: #333;
}
.light .shelf-cell {
    background: #E2E2E2;    
 }
 .light .shelfitem-title {
    color: #333;  
}
.light .editor .link-button,
.light .editor .link-button:hover,
.light .editor .link-button:focus
{
    color: #000;
    border: 1px solid #000;
}


/* Dark Theme */
.dark {
    /* background-color: #1E1E1E; */
    color: #C2C2C2;
}
.dark header {
    color: #fff;
}
.dark hr {
    border: 0;
    border-top: 1px solid #414648;
}
.dark .menu-modal-content hr {
    border-top: 1px solid #FFF;
}
.dark a {
    color: #63B531;
}
.dark .modal {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}
.dark .menu-modal-content,
.dark .search-modal-content {
    background-color: #2D2D2D;    
}  
.dark .menu-modal-content a, 
.dark .menu-modal-content button,
.dark .search-modal-content-button {
    color: #E2E2E2;
}

/* .dark .shelf-cell {
    background: #2e2e2e;    
 } */
 .dark .shelfitem-title {
    color: #CCC;  
}
.dark .editor .link-button,
.dark .editor .link-button:hover,
.dark .editor .link-button:focus
{
    color: #fff;
    border: 1px solid #fff;
}
