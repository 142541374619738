

header {
  padding: 0px 0 0px 0px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  justify-content: flex-end;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 9;
  height: inherit;
}

header h1 {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.3px;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}

hr {
  width: 100%;
}

hr.thin {
  width: 100%;
  margin: 8px 0px;
}

.icon-button,
.link-button {
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  text-decoration: none;
  background-color: transparent;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
  cursor: pointer;
  border: 1px;
}

.container {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 30px;
}

.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.display-item {
  width: 65%;
  margin-left: 50px;
}

.display-item button {
  margin-top: 20px;
  margin-left: auto;
  display: block;
}

.add-item form {
  width: 100%;
  overflow: hidden;
}