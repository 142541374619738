.infopage {
    margin: auto;
    width: 100%;
    max-width: 600px;    
    padding: 0px;
    justify-items: center;
    align-items: start;
}

.infopage .cover {
    width: 100%;
    max-width: 400px;
    left: 50%;
    margin: 10px 100px;
}

 
  /* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
  @media screen and (max-width: 600px) {
    .search-modal-content {
        width: 96%;
    }
    .search-modal-content-button {
        padding: 5px;        
    }
  }

 @media only screen and (max-width: 768px) {
    .menu-modal-content {
        font-size: 1.1em;
        width: 125px;
    }
}