.shelf-container {
  white-space: nowrap;
  margin: auto;
  padding: 10px 0 0 0;
  
}

.shelf {
  /* width: 95%; */
  /* padding: 5px 0 0 10px; */
  margin-bottom: 4px;
  margin-top: 4px;

}

.shelf-name {
  padding: 10px 0 0 10px;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: auto;
}

.shelf-name > a {
  color: unset;
  text-decoration: none;
}
.shelf-name > a:hover,
.shelf-name > a:focus
 {
  text-decoration: underline;
}

.shelfitem-title {
  font-size: 0.69em;
  padding: 4px 0 0 10px;
  text-align: start;
  position: absolute;
  top: 85%;  
  width: 87px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.shelfitem-info {
  font-size: 0.69em;
  padding: 4px 0 0 10px;
  text-align: end;
  position: absolute;
  top: 85%;
  left: 70%;   
  text-decoration: none; 
}

/* .flickity-viewport {
  overflow: visible;
} */

.shelf-cell {
  /* display: inline-block; */
  /* overflow: hidden; */
  width: 290px;
  height: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 4px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px #555;
  /* background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42)); */
  /* z-index: 4; */
  -webkit-transition: all 300ms ease-out 0s;
  -moz-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s; 
}

.shelf-cell:hover {
  border: 4px solid rgba(255, 255, 255, 0.6);
  width: 296px;
  left: -3px;
  /* height: 164px;
  top: -2px; */
}

.shelf-cell img {
  width: 100%;
  height: 84%;
  object-fit: cover;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

/* .shelf-cell img:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5); 
  transform: scale(1.2); 
} */

/* 
.flickity-enabled.is-draggable .flickity-viewport,
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: default;
} */

.shelf .flickity-button {
  display: none;
  background: #222;
}
.shelf .flickity-button:hover {
  background: #333;
}

.shelf .flickity-prev-next-button {
  width: 30px;
  height: 100px;
  border-radius: 5px;
  top: 60px;
}
/* icon color */
.shelf .flickity-button-icon {
  fill: #999;
}
/* position outside */
.shelf .flickity-prev-next-button.previous {
  left: -40px;
}
.shelf .flickity-prev-next-button.next {
  right: -40px;
}

.shelf .flickity-button:focus,
.shelf .flickity-page-dot:focus {
  outline: none;
  box-shadow: 0 0 0 1px #CCC;
}

.shelf .flickity-page-dots {
  bottom: -15px;
}

.shelf .flickity-page-dots .flickity-page-dot {
  height: 4px;
  width: 10px;
  border-radius: 0;
  opacity: 0.6;
  background: #666;
  border: 1px solid #999;
  border-radius: 1px;
  padding: 0;
  margin: 4px;
}

.shelf .flickity-page-dot.is-selected {  
  border: 0px solid #FFF;
  border-radius: 1;
  opacity: 0.9;
  height: 4px;  
  outline: 1px solid #999;
}