.navbar-line {
    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
    width: 100%;
    border: 1px solid #414648;
    margin: 0px;
}

.modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-content {
    background-color: #2D2D2D;
    margin: 17px auto;
    padding: 20px;
    width: 90%;
    max-width: 350px;
    border-radius: 10px;    
}

.modal-text {
    font-family: 'Roboto Mono', Geneva, Verdana, sans-serif;
    color: #C2C2C2;
    font-size: 0.82em;
    max-width: 320px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.codabulo-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.recode-logo-block {
    position: absolute;
    left: 50%;    
    transform: translate(-50%, 0);
    padding: 20px;
}

.recode-logo {    
    position: relative;
    display: grid;
    padding: 0px;    
    grid-template-columns: 50px 230px 50px;
    justify-items: center;
    align-items: start;
}

.recode-logo-text {
    font-family: "SpaceClaim ISO CB", Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.2em;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #63B531;
}

.recode-braces {
    color: #1CD3FB;
}

.recode-text {
    font-family: "SpaceClaim ISO CB", Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.13em;
    letter-spacing: -0.03em;
    color: #63B531;
}

#story_art_play {
    display: block;
    width: 270px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .modal-content {
        max-width: 80%;
    }
    .modal-text {
        max-width: 95%;
        font-size: 1.1em;
    }
    .recode-logo-text {
        font-size: 1.9em;
    }
}