.navbar {
    position: relative;
    width: 100%;
    display: grid;
    padding: 0px;
    grid-template-columns: 50px auto 50px 50px;
    justify-items: center;
    align-items: start;
    transition: 0.5s;
}

.navscroll {
    background-color: rgba(0,0,0,0.8);
}

hr.navbar-line {
    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
}

.button-navbar-back {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding: 10px 0px 5px 0px;
}

a.button-navbar-back {
    display: flex;
    align-items: center;
}

.button-projectName-button {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    padding: 8px 0px 2px 46px;
}
.button-projectName-button img {
    width: 100px;
    height: 40px;
}

.button-menu-button {
    padding: 0px;
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
    padding: 13px 18px 3px 5px;
}

.button-search-button {
    justify-self: end;
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    padding: 15px 0px 3px 5px;
}

.button-navbar-back img,
.button-projectName-button img,
.button-menu-button img,
.button-search-button img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
}

.menu-close {
    float: right;
}
.menu-close:hover,
.menu-close:focus {
    cursor: pointer;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;    
    height: 100%;
    overflow: auto;
}

.menu-modal-content {
    float: right;
    width: 115px;
    margin: 10px 10px 0 0;
    padding: 7px;
    border-radius: 10px;
}

.menu-modal-content ul {
    list-style-type: none;
    margin: 26px 10px 5px 10px;
    padding: 0;
    overflow: hidden;
}
 
.menu-modal-content li {
    margin: 5px 0px;     
}
  
.menu-modal-content a, 
.menu-modal-content button {
    padding: 0;
    border: 1px;
 }

.menu-user {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-user > img {    
    height: 24px;
    width: 24px; 
    margin-right: 5px;
}
.menu-user > div {        
    padding: 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;    
    font-size: 11px;   
    width: 100%;
    text-align: left;
    line-height: 12px;    
}

.search-modal-content {
    float: right;
    display: grid;
    grid-template-columns: 45px auto;
    width: 500px;
    margin: 5px;
    padding: 3px;
    border-radius: 10px;   
}

.search-close {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding: 5px;
    width: 24px;
    height: 24px;
}
.search-close:hover,
.search-close:focus {
    cursor: pointer;
}

.search-form {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    float: right;    
}
.search-form-content {
    display: grid;
    grid-template-columns: auto 35px;
    width: 100%;
}
.search-input {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: 100%;
}
.search-submit {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}

/* Style the search box inside the navigation bar */
.search-modal-content input[type=text] {    
    float: right;
    padding: 6px;
    border: none;
    margin: 2px 0 2px 0;
    height: 20px;
    font-size: 0.84em;
    border-radius: 6px 0px 0px 6px;
    width: 100%
  }

  .search-modal-content-button {
    float: left;
    padding: 4px;
    border: none;
    margin: 2px 2px 0 0;
    border-radius: 0px 6px 6px 0px;
  }
  
  /* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
  @media screen and (max-width: 600px) {
    .search-modal-content {
        width: 96%;
    }
    .search-modal-content-button {
        padding: 5px;        
    }
  }

 @media only screen and (max-width: 768px) {
    .menu-modal-content {
        font-size: 1.1em;
        width: 125px;
    }
}