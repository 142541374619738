.carousel {
  /* background: #EEE; */
}
.carousel-cell {
  width: 94%;
  height: 300px;
  margin-right: 0px;
  border-radius: 5px;
  transition: opacity 0.2s ease 0s;
}

.carousel-cell img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* dim unselected */
  opacity: 0.5;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  /* -webkit-filter: blur(2px);
          filter: blur(2px); */
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s, transform 0.3s, -webkit-filter 0.3s, filter 0.3s;
          transition: opacity 0.3s, transform 0.3s, filter 0.3s;    
}

.carousel-cell.is-selected img {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  /* -webkit-filter: none;
  filter: none; */
}


.carousel .flickity-button:focus,
.carousel .flickity-page-dot:focus {
  outline: none;
  box-shadow: 0 0 0 2px #9F9F9F;
}

.carousel .flickity-page-dots {
  bottom: 10px;
}

.carousel .flickity-page-dots .flickity-page-dot {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  background: transparent;
  border: 2px solid white;
}

.carousel .flickity-page-dot.is-selected {
  background: white;
}