.acountpage {
    position: relative;
    width: 100%;
    /* display: grid;
    padding: 0px;
    grid-template-columns: 50px auto 50px 50px;
    justify-items: center;
    align-items: start; */
}

 
  /* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
  @media screen and (max-width: 600px) {
    .search-modal-content {
        width: 96%;
    }
    .search-modal-content-button {
        padding: 5px;        
    }
  }

 @media only screen and (max-width: 768px) {
    .menu-modal-content {
        font-size: 1.1em;
        width: 125px;
    }
}